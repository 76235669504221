/*------------------------------------*
	@COMPONENTS #HERO
\*------------------------------------*/

.section {
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: $screen-sm) {	
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @media (max-width: $screen-xs) {	
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.section--black {
    background-color: $black;
}

.section--map {
    padding-top: 40px;
    padding-bottom: 0;

    .map {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: 50% 50%;
        background-color: #fff;
    
        @media (max-width: $screen-sm) {		
            height: calc(100vh - 60px);
        }
    
        iframe {
            width: 100%;
            height: 100%;
        }

        @media (max-width: $screen-xs) {	
            height: 500px;
            
        }
    }
}

.section--map {
    padding-top: 40px;
    padding-bottom: 0;

    .content {
        min-width: 320px;
    }

    .map {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 600px;
        background-size: cover;
        background-position: 50% 50%;
        background-color: #fff;
    
        @media (max-width: $screen-sm) {		
            height: calc(100vh - 60px);
        }
    
        iframe {
            width: 100%;
            height: 100%;
        }

        @media (max-width: $screen-xs) {	
            height: 500px;
            
        }
    }
}

    .section__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 60px;
        background-color: rgba(#fff, 0.7);
        position: relative;

        @media (max-width: $screen-sm) {	
            padding: 40px;
        }

        @media (max-width: $screen-xs) {	
            padding: 30px;
        }
    }

    .section__logo {
        text-align: center;
        margin-bottom: 40px;

        img {
			height: 40px;
		}

        @media (max-width: $screen-sm) {	
            margin-bottom: 30px;
        }
    }

    .section__title {
        font-family: $alt-font;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 40px;
        text-align: center;

        @media (max-width: $screen-sm) {	
            font-size: 32px;
        }

        @media (max-width: $screen-xs) {	
            font-size: 24px;
            margin-bottom: 30px;
        }
    }

    .section__img {
        display: none;
		opacity: 0.8 !important;
        height: 80px;
		z-index: 101;
        position: absolute;
        top: 10px;
        right: 20px;
	
		@media (max-width: 880px) {
            display: flex;
			justify-content: center;
            align-items: center;
		}

        @media (max-width: $screen-xs) {	
            height: 60px;
        }
    }

    .section__subtitle {
        font-family: $alt-font;
        font-size: 18px;
        margin-bottom: 30px;

        @media (max-width: $screen-xs) {	
            margin-bottom: 15px;
        }
    }

    .section__text {
        @media (max-width: $screen-md) {	
            line-height: 1.5;
        }
    }