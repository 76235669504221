/*------------------------------------*
	@COMPONENTS #HEADER
\*------------------------------------*/

.header {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: rgba(#fff, 0.5);
	z-index: 100;
	position: relative;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 100%;
		width: 1200px;
	}

	@media (max-width: $screen-sm) {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

	.header__logo {
		position: relative;
		display: inline-flex;
		margin-bottom: 0px;

		img {
			height: 40px;
		}

		&:hover {
			text-decoration: none;
			opacity: 0.5;
		}

		@media (max-width: 850px) {	
		}
	}

	.header__button {
		display: none;

		@media (max-width: $screen-sm) {
			background-color: transparent;	
			display: block;
			position: absolute;
			top: 15px;
			right: 25px;
			
			.icon {
				color: $white;
			}
		}

		&--dark {
			.icon {
				color: $black;
			}
		}
	}

	.header__btn-close {
		display: none;

		.headermenu-on & {
			display: block;
			color: $black;
			background-color: transparent;
			padding: 0;
			position: absolute;
			top: 15px;
			right: 25px;
			z-index: 500;

			.icon {
				color: $black;
			}
		}
	}

	.header__menu {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		list-style: none;
		margin-bottom: 0;
				
		li {
			a {
				color: $black;
				font-size: 16px;
			}
			
			&:not(:last-child) {
				margin-right: 30px;
			}

			&:hover {
				a {
					text-decoration: none;
					opacity: 0.5;
				}
			}
		}

		@media (max-width: $screen-lg) {	
			li {
				a {
					font-size: 14px;
					white-space: nowrap;
				}

				&:not(:last-child) {
					margin-right: 15px;
				}
			}
		}

		@media (max-width: 850px) {	
			padding-left: 20px;
		}

		@media (max-width: $screen-sm) {	
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: $white;
			padding: 100px 0;
			position: fixed;
			top: 0px;
			left: 0;
			width: 100vw;
			height: 100vh;
			@include hide;
			@include trans;
			z-index: 400;

			li {
				a {
					font-family: $alt-font;
					color: $red;
					font-size: 18px;
				}

				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 15px;
				}
			}

			.headermenu-on & {
				@include show;
			}
		}
	}