/*------------------------------------*
	@COMPONENTS #HERO
\*------------------------------------*/

.hero {
	margin-top: -110px;
    // padding: 30px;
    position: relative;
    min-height: 56.25vw;
    overflow: hidden;
    display: flex;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: #15100e;
		opacity: 0.5;
	}

	.container {
		position: relative;
		z-index: 1;
		    display: flex;
    		flex-direction: column;
    		align-items: center;
    		justify-content: center;
			margin-top: -100px;
	}

	@media (max-width: $screen-lg) {
		padding-top: 160px;

		.container {
			justify-content: flex-start;
			margin-top: 0;
		}
	}

	@media (max-width: $screen-md) {
		padding-top: 140px;
		padding-bottom: 40px;
	}
}

.hero__video {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
}

	.hero__title {
		font-family: $alt-font;
		font-size: 68px;
		margin-bottom: 30px;
		line-height: 1;
		font-weight: 400;
		text-align: center;
		font-weight: 700;
		color: $white;

		span {
			color: $red;
		}

		@media (max-width: 1240px) {
			font-size: 54px;
			margin-bottom: 50px;
		}

		@media (max-width: 900px) {
			font-size: 34px;
			margin-bottom: 30px;
		}
	}

	.hero__subtitle {
		font-family: $alt-font;
		font-size: 28px;
		color: #ffff;
		line-height: 1.25;
		text-align: center;

		@media (max-width: 1240px) {
			font-size: 18px;
		}

		@media (max-width: 900px) {
			font-size: 16px;
		}
	}

	.hero__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		background-color: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		z-index: 6;
		padding-top: 50px;
		
		.icon {
			width: 20px;
			height: 20px;
			color: $white;
		}

		@media (max-width: $screen-sm) {	
			padding-top: 100px;
		}

		@media (max-width: 650px) {	
			display: none;
		}
	}