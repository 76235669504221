/*------------------------------------*
	@COMPONENTS #PHOTO
\*------------------------------------*/

.photo-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    > * {
        margin-right: 15px;
        margin-left: 15px;

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    @media (max-width: $screen-sm) {	
        > * {
            margin-right: 5px;
            margin-left: 5px;
            margin-bottom: 10px !important;
        }
    }
}

.photo {
    position: relative;
    height: 170px;
    width: 170px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        content: '';
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 3s ease;
    }

    &::after {
        content:'';
        width: 100%;
        height: 100%;
        background-color: rgba(42, 61, 69, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        @include hide;
        @include trans;
    }

    .icon {
        color: $white;
        font-size: 48px;
        width: 36px;
        height: 36px;
        letter-spacing: 4px;

        @include hide;
        @include trans;
        z-index: 99;
    }

    &:hover {
        cursor: pointer;

        &::after, .icon {
            @include show;
        }
    }

    @media (max-width: $screen-sm) {	
        height: 70px;
        width: 70px;

        .icon {
            font-size: 22px;
            width: 20px;
            height: 20px;
        }
    }
}