/*------------------------------------*\
	@COMPONENTS #LIGHTBOX
\*------------------------------------*/

.lb-nav {
    .lb-prev,
    .lb-next {
        background: none !important;

        &::after {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 40px;
            z-index: 10;
            color: $red;
            @include centerV;
        }
    }
    
    .lb-prev {
        &::after {
            content: '\f104';
            left: -40px;
        }
    }

    .lb-next {
        &::after {
            content: '\f105';
            right: -40px;
        }
    }
}