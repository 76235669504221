/*------------------------------------*\
	@COMPONENTS #LIST
\*------------------------------------*/

.list-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 250px;

    @media (max-width: $screen-sm) {	
        flex-direction: column;
        align-items: center;
    }
}
    .list__item {
        min-width: 250px;
        padding-left: 30px;
        padding-right: 30px;

        &:not(:last-child) {
            margin-right: 30px;
        }

        @media (max-width: $screen-sm) {	
            min-width: 250px;
            padding-left: 0;
            padding-right: 0;

            &:not(:last-child) {
                margin-right: 0px;
                margin-bottom: 40px;
            }
        }
    }

    .dl-row {
    display: flex;
    flex: 1;
    margin-bottom: 20px;

    > * {
        &:not(:first-child) {
            margin-left: 10px;
        }
    }

    dd {
        min-width: 50px;
    }

    dt {
        color: $red;
        
        a {
            color: $red
        }
    }

    @media (max-width: $screen-md) {	
        margin-bottom: 0;
        justify-content: space-between;

        dd {
            min-width: 80px;
        }
    }
}

.dl-row--alt {
    flex-direction: column;
}

// .list-wrap {
//     @media (max-width: $screen-md) {	
//         margin-bottom: 40px;
//         max-width: 300px;
//         width: 100%;
//     }
// }

