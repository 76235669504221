/*------------------------------------*
	@COMPONENTS #LAYOUT
\*------------------------------------*/

.page {
	&.headermenu-on {
		overflow: hidden !important;
	}
}

.main {
	flex: 1 0 auto;
	scroll-behavior: smooth;
}

	.sertifikat__img {
		justify-content: center;
		align-items: center;
		opacity: 0.8 !important;
		height: 100px;
		position: fixed;
		right: 20px;
		top: 80px;
		z-index: 101;
	
		@media (max-width: $screen-sm) {
			height: 80px;
		}
	
		@media (max-width: 880px) {
			display: none;
		}
	}
