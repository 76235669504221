/*------------------------------------*
	@COMPONENTS #FOOTER
\*------------------------------------*/

.footer {
	background-color: $red;
	padding-top: 10px;
	padding-bottom: 10px;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			margin-bottom: 0;
			color: $black;
			
			&:not(:first-child) {
				position: relative;
				padding-left: 10px;
				margin-left: 10px;

				&::before {
					content: '';
					width: 1px;
					height: 10px;
					background-color: $black;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
				}
			}
		}

		@media (max-width: $screen-xs) {	
			flex-direction: column;

			p {
				margin-bottom: 0;
				
				&:not(:first-child) {	
					&::before {
						width: 100%;
						height: 1px;
						background-color: $black;
						position: absolute;
						top: 2px;
						transform: none;
						left: 0;
					}
				}
			}
		}
	}
}

.footer__btn {
	padding: 8px 10px;
	justify-content: center;
	align-items: center;
	opacity: 0.8 !important;
	background-color: $red !important;
	position: fixed;
	right: 20px;
	bottom: 50%;
	z-index: 101;
	display: none;

	svg {
		color: $white !important;
	}

	&:hover {
		opacity: 0.5 !important;
	}

	&.footer__btn--on {
		display: flex;
	}

	@media (max-width: $screen-sm) {

		.icon-angle-up {
			width: 15px;
			height: 15px;
		}
	}

	@media (max-width: $screen-xs) {
		bottom: 55px;
	}
}

