@charset "UTF-8";
/* -----------------------------------------------------
+
+ FRONT-MAN.com
+
------------------------------------------------------ */
/*------------------------------------*
    @BASE #PAGE
\*------------------------------------*/
html {
  min-height: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 2;
  font-weight: 500;
  background-color: #fff;
  color: #333;
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
}

body * {
  box-sizing: border-box;
}

::-moz-selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

/*------------------------------------*
    @BASE #TYPE
\*------------------------------------*/
h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child,
.h1:first-child, .h2:first-child, .h3:first-child, .h4:first-child, .h5:first-child, .h6:first-child {
  margin-top: 0;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child,
.h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child {
  margin-bottom: 0;
}

dl dd {
  margin-bottom: 0;
}

img,
p,
ul,
ol,
dl,
pre,
blockquote {
  margin-bottom: 30px;
}

img:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
pre:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

hr {
  background-color: none;
  border: none;
  border-bottom: 1px solid #eee;
  height: 1px;
  margin: 30px 0;
}

/*------------------------------------*
    @BASE #LINKS
\*------------------------------------*/
a {
  outline: none !important;
  /* removing outline in Mozilla */
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease 0s;
}

/*------------------------------------*
    @OBJECTS #BUTTONS
\*------------------------------------*/
button {
  cursor: pointer;
  transition: all .3s ease 0s;
}

button, button:focus, button:active {
  outline: none !important;
}

button:not(.btn), button:focus:not(.btn), button:active:not(.btn) {
  border: none;
}

*[class*="btn-"] {
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: none;
  margin: 0;
  cursor: pointer;
  overflow: visible;
  transition: all .3s ease 0s;
  border-radius: 0;
  border: none;
  padding: 0 20px;
}

*[class*="btn-"], *[class*="btn-"]:hover, *[class*="btn-"]:active, *[class*="btn-"]:focus {
  text-decoration: none;
}

/*------------------------------------*
    @OBJECTS #TABLES
\*------------------------------------*/
table,
.table {
  width: 100%;
}

table th,
.table th {
  font-weight: normal;
}

/*------------------------------------*
    @OBJECTS #FORMS
\*------------------------------------*/
.form-control {
  vertical-align: middle;
  transition: all .3s ease 0s;
}

textarea.form-control {
  padding: 10px 15px;
  resize: none;
  min-height: 150px;
  line-height: 2;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*------------------------------------*
    @OBJECTS #ICONS
\*------------------------------------*/
.icon {
  fill: currentColor;
  height: 24px;
  width: 24px;
  vertical-align: middle;
  display: inline-block;
  transition: all .3s ease 0s;
}

.btn .icon:last-child {
  margin-left: 10px;
}

.btn .icon:first-child {
  margin-right: 10px;
}

.lb-loader, .lightbox {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: .8;
  display: none;
}

.lightbox {
  width: 100%;
  z-index: 10000;
  font-weight: 400;
  outline: 0;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid #fff;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  top: 43%;
  height: 25%;
  width: 100%;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
}

.lb-next, .lb-prev {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
}

.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
}

.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: 0;
  filter: alpha(Opacity=70);
  opacity: .7;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(Opacity=100);
  opacity: 1;
}

/*------------------------------------*
	@COMPONENTS #LAYOUT
\*------------------------------------*/
.page.headermenu-on {
  overflow: hidden !important;
}

.main {
  flex: 1 0 auto;
  scroll-behavior: smooth;
}

.sertifikat__img {
  justify-content: center;
  align-items: center;
  opacity: 0.8 !important;
  height: 100px;
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 101;
}

@media (max-width: 768px) {
  .sertifikat__img {
    height: 80px;
  }
}

@media (max-width: 880px) {
  .sertifikat__img {
    display: none;
  }
}

/*------------------------------------*
	@COMPONENTS #HEADER
\*------------------------------------*/
.header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  position: relative;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 1200px;
}

@media (max-width: 768px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header__logo {
  position: relative;
  display: inline-flex;
  margin-bottom: 0px;
}

.header__logo img {
  height: 40px;
}

.header__logo:hover {
  text-decoration: none;
  opacity: 0.5;
}

.header__button {
  display: none;
}

@media (max-width: 768px) {
  .header__button {
    background-color: transparent;
    display: block;
    position: absolute;
    top: 15px;
    right: 25px;
  }
  .header__button .icon {
    color: #fff;
  }
}

.header__button--dark .icon {
  color: #000;
}

.header__btn-close {
  display: none;
}

.headermenu-on .header__btn-close {
  display: block;
  color: #000;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 500;
}

.headermenu-on .header__btn-close .icon {
  color: #000;
}

.header__menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}

.header__menu li a {
  color: #000;
  font-size: 16px;
}

.header__menu li:not(:last-child) {
  margin-right: 30px;
}

.header__menu li:hover a {
  text-decoration: none;
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .header__menu li a {
    font-size: 14px;
    white-space: nowrap;
  }
  .header__menu li:not(:last-child) {
    margin-right: 15px;
  }
}

@media (max-width: 850px) {
  .header__menu {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .header__menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 100px 0;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease 0s;
    z-index: 400;
  }
  .header__menu li a {
    font-family: "Ruda", sans-serif;
    color: #CD171F;
    font-size: 18px;
  }
  .header__menu li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .headermenu-on .header__menu {
    opacity: 1;
    visibility: visible;
  }
}

/*------------------------------------*
	@COMPONENTS #FOOTER
\*------------------------------------*/
.footer {
  background-color: #CD171F;
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .container p {
  margin-bottom: 0;
  color: #000;
}

.footer .container p:not(:first-child) {
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
}

.footer .container p:not(:first-child)::before {
  content: '';
  width: 1px;
  height: 10px;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

@media (max-width: 560px) {
  .footer .container {
    flex-direction: column;
  }
  .footer .container p {
    margin-bottom: 0;
  }
  .footer .container p:not(:first-child)::before {
    width: 100%;
    height: 1px;
    background-color: #000;
    position: absolute;
    top: 2px;
    transform: none;
    left: 0;
  }
}

.footer__btn {
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  opacity: 0.8 !important;
  background-color: #CD171F !important;
  position: fixed;
  right: 20px;
  bottom: 50%;
  z-index: 101;
  display: none;
}

.footer__btn svg {
  color: #fff !important;
}

.footer__btn:hover {
  opacity: 0.5 !important;
}

.footer__btn.footer__btn--on {
  display: flex;
}

@media (max-width: 768px) {
  .footer__btn .icon-angle-up {
    width: 15px;
    height: 15px;
  }
}

@media (max-width: 560px) {
  .footer__btn {
    bottom: 55px;
  }
}

/*------------------------------------*
	@COMPONENTS #HERO
\*------------------------------------*/
.hero {
  margin-top: -110px;
  position: relative;
  min-height: 56.25vw;
  overflow: hidden;
  display: flex;
}

.hero::after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #15100e;
  opacity: 0.5;
}

.hero .container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
}

@media (max-width: 1200px) {
  .hero {
    padding-top: 160px;
  }
  .hero .container {
    justify-content: flex-start;
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .hero {
    padding-top: 140px;
    padding-bottom: 40px;
  }
}

.hero__video {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
}

.hero__title {
  font-family: "Ruda", sans-serif;
  font-size: 68px;
  margin-bottom: 30px;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
  color: #fff;
}

.hero__title span {
  color: #CD171F;
}

@media (max-width: 1240px) {
  .hero__title {
    font-size: 54px;
    margin-bottom: 50px;
  }
}

@media (max-width: 900px) {
  .hero__title {
    font-size: 34px;
    margin-bottom: 30px;
  }
}

.hero__subtitle {
  font-family: "Ruda", sans-serif;
  font-size: 28px;
  color: #ffff;
  line-height: 1.25;
  text-align: center;
}

@media (max-width: 1240px) {
  .hero__subtitle {
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .hero__subtitle {
    font-size: 16px;
  }
}

.hero__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 6;
  padding-top: 50px;
}

.hero__btn .icon {
  width: 20px;
  height: 20px;
  color: #fff;
}

@media (max-width: 768px) {
  .hero__btn {
    padding-top: 100px;
  }
}

@media (max-width: 650px) {
  .hero__btn {
    display: none;
  }
}

/*------------------------------------*
	@COMPONENTS #HERO
\*------------------------------------*/
.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 768px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 560px) {
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section--black {
  background-color: #000;
}

.section--map {
  padding-top: 40px;
  padding-bottom: 0;
}

.section--map .map {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #fff;
}

@media (max-width: 768px) {
  .section--map .map {
    height: calc(100vh - 60px);
  }
}

.section--map .map iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 560px) {
  .section--map .map {
    height: 500px;
  }
}

.section--map {
  padding-top: 40px;
  padding-bottom: 0;
}

.section--map .content {
  min-width: 320px;
}

.section--map .map {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #fff;
}

@media (max-width: 768px) {
  .section--map .map {
    height: calc(100vh - 60px);
  }
}

.section--map .map iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 560px) {
  .section--map .map {
    height: 500px;
  }
}

.section__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px;
  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
}

@media (max-width: 768px) {
  .section__content {
    padding: 40px;
  }
}

@media (max-width: 560px) {
  .section__content {
    padding: 30px;
  }
}

.section__logo {
  text-align: center;
  margin-bottom: 40px;
}

.section__logo img {
  height: 40px;
}

@media (max-width: 768px) {
  .section__logo {
    margin-bottom: 30px;
  }
}

.section__title {
  font-family: "Ruda", sans-serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

@media (max-width: 768px) {
  .section__title {
    font-size: 32px;
  }
}

@media (max-width: 560px) {
  .section__title {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

.section__img {
  display: none;
  opacity: 0.8 !important;
  height: 80px;
  z-index: 101;
  position: absolute;
  top: 10px;
  right: 20px;
}

@media (max-width: 880px) {
  .section__img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 560px) {
  .section__img {
    height: 60px;
  }
}

.section__subtitle {
  font-family: "Ruda", sans-serif;
  font-size: 18px;
  margin-bottom: 30px;
}

@media (max-width: 560px) {
  .section__subtitle {
    margin-bottom: 15px;
  }
}

@media (max-width: 992px) {
  .section__text {
    line-height: 1.5;
  }
}

/*------------------------------------*\
	@COMPONENTS #LIST
\*------------------------------------*/
.list-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 250px;
}

@media (max-width: 768px) {
  .list-wrap {
    flex-direction: column;
    align-items: center;
  }
}

.list__item {
  min-width: 250px;
  padding-left: 30px;
  padding-right: 30px;
}

.list__item:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 768px) {
  .list__item {
    min-width: 250px;
    padding-left: 0;
    padding-right: 0;
  }
  .list__item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 40px;
  }
}

.dl-row {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
}

.dl-row > *:not(:first-child) {
  margin-left: 10px;
}

.dl-row dd {
  min-width: 50px;
}

.dl-row dt {
  color: #CD171F;
}

.dl-row dt a {
  color: #CD171F;
}

@media (max-width: 992px) {
  .dl-row {
    margin-bottom: 0;
    justify-content: space-between;
  }
  .dl-row dd {
    min-width: 80px;
  }
}

.dl-row--alt {
  flex-direction: column;
}

/*------------------------------------*
	@COMPONENTS #BLOCK
\*------------------------------------*/
.block-wrap {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .block-wrap {
    margin-bottom: 0px;
  }
}

.block {
  display: block;
  position: relative;
  background-position: center;
  background-size: cover;
  min-height: 370px;
  transition: all .3s ease 0s;
  overflow: hidden;
}

.block .icon {
  color: #fff !important;
  position: absolute;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0s;
  transition-delay: 0s;
}

.block span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  color: #fff;
  font-family: "Ruda", sans-serif;
  font-size: 36px;
  line-height: 1.2;
  font-weight: bold;
  transition: all .3s ease 0s;
  z-index: 2;
}

.block span::after {
  content: '';
  height: 5px;
  width: 100%;
  background-color: #CD171F;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}

.block::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #15100e;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.block::before {
  content: '';
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.6) 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.block:hover span {
  top: 52%;
}

.block:hover span::after {
  opacity: 1;
}

.block:hover::before {
  opacity: 1;
  visibility: visible;
}

.block:hover .icon {
  top: 90px;
  opacity: 1;
  visibility: visible;
  transition-duration: 0.5s;
  transition-delay: 0.2s;
}

@media (max-width: 992px) {
  .block {
    min-height: 250px;
  }
  .block span {
    font-size: 28px;
  }
  .block span::after {
    content: '';
    height: 4px;
  }
}

@media (max-width: 768px) {
  .block {
    margin-bottom: 15px !important;
  }
  .block span {
    font-size: 28px;
  }
  .block span::after {
    content: '';
    height: 3px;
  }
}

@media (max-width: 560px) {
  .block {
    margin-bottom: 15px !important;
  }
  .block span {
    font-size: 22px;
  }
  .block span::after {
    content: '';
    height: 3px;
  }
}

/*------------------------------------*\
	@COMPONENTS #LIGHTBOX
\*------------------------------------*/
.lb-nav .lb-prev,
.lb-nav .lb-next {
  background: none !important;
}

.lb-nav .lb-prev::after,
.lb-nav .lb-next::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 40px;
  z-index: 10;
  color: #CD171F;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lb-nav .lb-prev::after {
  content: '\f104';
  left: -40px;
}

.lb-nav .lb-next::after {
  content: '\f105';
  right: -40px;
}

/*------------------------------------*
	@COMPONENTS #PHOTO
\*------------------------------------*/
.photo-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.photo-wrap > * {
  margin-right: 15px;
  margin-left: 15px;
}

.photo-wrap > *:not(:last-child) {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .photo-wrap > * {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px !important;
  }
}

.photo {
  position: relative;
  height: 170px;
  width: 170px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo span {
  content: '';
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 3s ease;
}

.photo::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(42, 61, 69, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.photo .icon {
  color: #fff;
  font-size: 48px;
  width: 36px;
  height: 36px;
  letter-spacing: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  z-index: 99;
}

.photo:hover {
  cursor: pointer;
}

.photo:hover::after, .photo:hover .icon {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .photo {
    height: 70px;
    width: 70px;
  }
  .photo .icon {
    font-size: 22px;
    width: 20px;
    height: 20px;
  }
}

/*------------------------------------*\
	@COMPONENTS #MODAL
\*------------------------------------*/
.modal-content {
  background-color: #fff;
  padding: 60px 40px;
  border: none !important;
}

@media (max-width: 768px) {
  .modal-content {
    border-radius: 0px !important;
    height: 100vh;
    width: 100%;
    height: 100%;
  }
}

.modal__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal__sert {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8 !important;
  height: 80px;
  z-index: 101;
  position: absolute;
  top: -50px;
  right: 0px;
}

@media (max-width: 560px) {
  .modal__sert {
    height: 60px;
    top: -60px;
    right: -15px;
  }
}

.modal__title {
  color: #000;
  margin-bottom: 30px;
  text-align: center;
}

.modal__subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.modal__photos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__photos > *:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .modal__photos {
    flex-direction: column;
  }
}

.modal__img {
  max-height: 300px;
  width: 300px;
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .modal__img {
    max-height: 250px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .modal__img:not(:last-child) {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

/*------------------------------------*
	@TRUMPS #COLORS
\*------------------------------------*/
.color-primary {
  color: #fff !important;
}

.color-alt {
  color: #CD171F !important;
}

/*------------------------------------*
	@TRUMPS #HELPERS
\*------------------------------------*/
.clean {
  margin: 0;
  padding: 0;
}

.no-transition,
.no-transition * {
  transition: none !important;
}

.amp {
  font-family: Baskerville, Palatino, "Palatino Linotype", "Book Antiqua", serif;
  font-size: 1.4em;
  font-style: italic;
  font-weight: normal;
}

.nowrap {
  white-space: nowrap;
}

/*------------------------------------*
	@TRUMPS #LAYOUT
\*------------------------------------*/
.spaced1x {
  margin-bottom: 10px !important;
}

.spaced2x {
  margin-bottom: 20px !important;
}

.spaced3x {
  margin-bottom: 30px !important;
}

.spaced4x {
  margin-bottom: 40px !important;
}

.spaced5x {
  margin-bottom: 50px !important;
}

.spaced6x {
  margin-bottom: 60px !important;
}

.spaced7x {
  margin-bottom: 70px !important;
}

.spaced8x {
  margin-bottom: 80px !important;
}

.spaced9x {
  margin-bottom: 90px !important;
}

.spaced10x {
  margin-bottom: 100px !important;
}

/*------------------------------------*
	@TRUMPS #PRINT
\*------------------------------------*/
/**
 * Very crude, reset-like styles taken from the HTML5 Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/3c3c5e64604209a4d63e1e4c48dd245d45fadfd9/css/main.css#L200-L234
 */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /**
     * Don’t show links that are fragment identifiers, or use the `javascript:`
     * pseudo protocol.
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
}
