/*------------------------------------*
	@TRUMPS #HELPERS
\*------------------------------------*/

.clean {
	margin: 0;
	padding: 0;
}

//NO TRANSITION CLASS
.no-transition {
    &,
    * {
        transition: none !important;
    }
}

//NICER AMPERSAND
.amp {
    font-family: Baskerville, Palatino, "Palatino Linotype", "Book Antiqua", serif;
    font-size: 1.4em;
    font-style: italic;
    font-weight: normal;
}

//NOWRAP
.nowrap {
    white-space: nowrap;
}