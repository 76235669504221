/*------------------------------------*
	@COMPONENTS #BLOCK
\*------------------------------------*/

.block-wrap {
    margin-bottom: 30px;

    @media (max-width: $screen-sm) {	
        margin-bottom: 0px;
    }
}

.block {
    display: block;
    position: relative;
    background: {
        position: center;
        size: cover;
    }
    min-height: 370px;
    @include trans;
    overflow: hidden;

    .icon {
        color: $white !important;
        position: absolute;
        @include centerH;
        top: 80px;
        @include hide;
        transition-duration: 0s;
        transition-delay: 0s;
    }
    
    span {
        @include centerH;
        top: 30px;
        color: $white;
        font-family: $alt-font;
        font-size: 36px;
        line-height: 1.2;
        font-weight: bold;
        @include trans;
        z-index: 2;

        &::after {
            content:'';
            height: 5px;
            width: 100%;
            background-color: $red;
            @include centerH;
            bottom: -10px;
        }
    }

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #15100e;
		opacity: 0.5;
        @include center;
        z-index: 1;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.6) 100%);
        @include center;
        @include hide;
        @include trans;
    }

    &:hover {
        span {
            top: 52%;

            &::after {
                opacity: 1;
            }
        }

        &::before {
            @include show;
        }

        .icon {
            top: 90px;
            @include show;
            transition-duration: 0.5s;
            transition-delay: 0.2s;
        }
    }

    @media (max-width: $screen-md) {	
        min-height: 250px;

        span {
            font-size: 28px;
    
            &::after {
                content:'';
                height: 4px;
            }
        }
    }

    @media (max-width: $screen-sm) { 
        margin-bottom: 15px !important;

        span {
            font-size: 28px;
    
            &::after {
                content:'';
                height: 3px;
            }
        }
    }

    @media (max-width: $screen-xs) { 
        margin-bottom: 15px !important;

        span {
            font-size: 22px;
    
            &::after {
                content:'';
                height: 3px;
            }
        }
    }
}