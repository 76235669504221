//------------------------------------\\
//  @SETTINGS #VARIABLES
//------------------------------------//

//COLORS
$red: #CD171F;
$white: #fff;
$black: #000;

$primary: 					$white;
$alt: 						$red;

// Backgrounds
$bgr: 						#fff;
$base-bgr:                  #fff;


$base-font:					'Roboto', sans-serif;
$alt-font:					'Ruda', sans-serif;

$base-font-size:            14px;
$base-line-height:         	2;
$base-font-weight:          500;

$text-clr: 					#333;
$text-alt: 					#999;
$placeholder-clr:			#ccc;
$link-clr:					$primary;
$link-hover:				darken($primary,10);

$border:					#eee;
$borderd:					#aaa;



// Layout
$space:                     30px;
$radius:					3px;


// Breakpoints
$screen-xs:                 560px;
$screen-sm:                 768px;
$screen-md:                 992px;
$screen-lg:                 1200px;

$breakpoint:     			991px !default;

// Select Text
$selected-text-bgr:			#333;
$selected-text-clr:			#fff;