/*------------------------------------*\
	@COMPONENTS #MODAL
\*------------------------------------*/

.modal-content {
	background-color: $white;
	padding: 60px 40px;
	border: none !important;

	@media (max-width: $screen-sm) {	
		border-radius: 0px !important;
		height: 100vh;
		width: 100%;
		height: 100%;
	}
}

.modal__box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.modal__sert {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.8 !important;
	height: 80px;
	z-index: 101;
	position: absolute;
	top: -50px;
	right: 0px;

	@media (max-width: $screen-xs) {	
		height: 60px;
		top: -60px;
		right: -15px;
	}
}

.modal__title {
	color: $black;
	margin-bottom: 30px;	
	text-align: center;
}

.modal__subtitle {
	font-size: 18px;
	text-align: center;
	margin-bottom: 20px;
}

.modal__photos {
	display: flex;
	justify-content: center;
	align-items: center;

	> * {
		&:not(:last-child) {
			margin-right: 20px;
		}
	}

	@media (max-width: $screen-sm) {
		flex-direction: column;	
	}
}

.modal__img {
	max-height: 300px;
	width: 300px;
	margin-bottom: 0;

	@media (max-width: $screen-lg) {	
		max-height: 250px;
		width: 100%;
	}

	@media (max-width: $screen-sm) {
		
		&:not(:last-child) {
			margin-bottom: 10px;
			margin-right: 0;
		}	
		
	}
}